import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["projectSelect", "assignee", "approver", "manager"]

  updateUsers() {
    const projectId = this.projectSelectTarget.value;
    fetch(`/tasks/project_users?project_id=${projectId}`)
      .then(response => response.json())
      .then(users => {
        this.updateSelectOptions(this.assigneeTarget, users);
      });
    fetch(`/tasks/project_users_non_assignee?project_id=${projectId}`)
      .then(response => response.json())
      .then(users2 => {
        this.updateSelectOptions(this.approverTarget, users2);
        this.updateSelectOptions(this.managerTarget, users2);
      });
  }

  updateSelectOptions(selectElement, users) {
    while (selectElement.options.length > 1) selectElement.remove(1);
    users.forEach(user => {
      const option = new Option(user.name, user.id);
      selectElement.add(option);
    });
  }
}


