import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="recipient-email"
export default class extends Controller {
  static targets = ["recipientEmails", "recipientUserIds"]

  addEmail(event) {
    const recipientEmailsInput = this.recipientEmailsTarget;
    const currentEmails = recipientEmailsInput.value.split(',').map(email => email.trim());
    const selectedEmails = Array.from(this.recipientUserIdsTarget.selectedOptions).map(option => option.value);

    // Build array of ALL emails in recipientUserIdsTarget
    const allEmailsFromOptions = Array.from(this.recipientUserIdsTarget.options).map(option => option.value);

    // Build array of emails in currentEmails but NOT in allEmailsFromOptions
    const emailsNotInOptions = currentEmails.filter(email => !allEmailsFromOptions.includes(email) && email !== "");

    // Combine selectedEmails and emailsNotInOptions
    const newEmails = [...new Set([...selectedEmails, ...emailsNotInOptions])]; 

    recipientEmailsInput.value = newEmails.join(', ');
  }
}
